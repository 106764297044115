import React from 'react';
import { CheckIcon } from '@heroicons/react/outline';
import { Link } from 'gatsby';
import { Helmet } from 'react-helmet';

import productScreenshot from '../../../images/screenshot-validation-vente.png';
import illuClosing from '../../../images/illu-closing.svg';
import illuPeaceful from '../../../images/illu-peaceful.svg';
import illuBudget from '../../../images/illu-budget.svg';
import illuDefense from '../../../images/illu-defense.svg';
import PageLayout from '../../../layouts/Page';
import Testimonials from '../../../components/Testimonials';
import ThankYouSmallLogo from '../../../images/small_logo.svg';

const transferFeatures = [
  {
    name: 'Rémunérez uniquement les produits livrés et non retournés',
    description: 'seules les commandes confirmées sont éligibles à une commission',
  },
  {
    name: 'Reportez les commandes en cours au mois prochain',
    description: 'évitez de rémunérer une commande qui pourrait être retournée après la livraison',
  },
  {
    name: 'Supprimez les ventes annulées ou non payées',
    description: 'erreurs de paiement en ligne, paiement par chèque, virement bancaire, etc...',
  },
];

const Page = () => {
  return (
    <PageLayout>
      <Helmet>
        <title>Validation des conversions automatisée avec TY Affiliate</title>
        <meta
          name="description"
          content="Rémunérez uniquement les conversions abouties et clôturez le mois en moins de 5 minutes."
        />
      </Helmet>
      <div className="bg-white">
        <div className="max-w-7xl mx-auto py-16 px-4 sm:pt-24 sm:pb-16 sm:px-6 lg:px-8">
          <div className="text-center">
            <h2 className="font-title text-3xl font-extrabold tracking-tight text-green-brand">
              <div className="relative flex flex-column justify-center items-center">
                <img
                  className="flex-shrink-0 h-12 w-12 mr-2 text-green-brand"
                  src={ThankYouSmallLogo}
                  alt="Thank-You Analytics Logo"
                />
                <span>Affiliate</span>
              </div>
            </h2>
            <p className="mt-4 max-w-6xl mx-auto font-title font-extrabold text-gray-900 text-2xl sm:tracking-tight lg:text-5xl">
              Validation des conversions automatisée
            </p>
            <p className="max-w-4xl mt-5 mx-auto text-xl text-gray-500">
              Rémunérez uniquement les conversions abouties.
              <br />
              Clôturez le mois en moins de 5 minutes
            </p>
          </div>
        </div>
      </div>

      <div className="bg-white sm:-mb-80">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <img className="" src={productScreenshot} alt="Affiliate Marketing" />
        </div>
      </div>

      <div className="pb-16 bg-white pt-16 sm:pt-96 overflow-hidden lg:pb-24">
        <div className="relative max-w-xl lg:max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 ">
          <div className="relative">
            <h2 className="text-center font-title text-2xl leading-8 font-extrabold tracking-tight text-green-brand sm:text-3xl">
              Gestion des statuts de commandes
            </h2>
          </div>

          <div className="relative mt-12 lg:mt-18 lg:grid lg:grid-cols-2 lg:gap-8 lg:items-center">
            <div className="mt-10 -mx-4 relative lg:mt-0" aria-hidden="true">
              <img className="relative mx-auto" width={400} src={illuClosing} alt="" />
            </div>

            <div className="relative">
              <dl className="mt-10 flex-wrap space-y-4 flex flex-column">
                {transferFeatures.map((item) => (
                  <div key={item.name} className="relative flex mt-2">
                    <CheckIcon className="h-6 w-6 text-green-brand" aria-hidden="true" />
                    <div className="ml-4">
                      <p className="text-lg leading-6 font-medium text-gray-900">{item.name}</p>
                      <dd className="mt-1 text-base text-gray-500">{item.description}</dd>
                    </div>
                  </div>
                ))}
              </dl>
            </div>
          </div>

          <div className="relative mt-12 sm:mt-16 lg:mt-24">
            <div className="lg:grid lg:grid-flow-row-dense lg:grid-cols-2 lg:gap-8 lg:items-center">
              <div className="lg:col-start-1">
                <h3 className="text-2xl font-extrabold text-green-brand font-title tracking-tight sm:text-3xl">
                  Maitrisez le budget de votre programme
                </h3>
                <p className="mt-3 text-lg text-gray-500">
                  Ne soyez plus surpris à la reception des factures de votre plateforme
                  d'affiliation. Un bilan est généré à chaque début de mois vous détaillant
                  précisement la situation du mois précédent.
                </p>
              </div>

              <div className="mt-10 -mx-4 relative lg:mt-0 lg:col-start-2">
                <img className="relative mx-auto" width={400} src={illuBudget} alt="" />
              </div>
            </div>
          </div>
          <div className="relative mt-12 sm:mt-16 lg:mt-24">
            <div className="lg:grid lg:grid-flow-row-dense lg:grid-cols-2 lg:gap-8 lg:items-center">
              <div className="lg:col-start-2">
                <h3 className="text-2xl font-extrabold text-green-brand font-title tracking-tight sm:text-3xl">
                  Supprimez les commissions frauduleuses
                </h3>
                <p className="mt-3 text-lg text-gray-500">
                  Nos algorithmes de détections des fraudes identifient au sein de chaque
                  transaction si l'affilié respecte bien les conditions de participations au
                  programme.
                  <br />
                  <br />
                  En cas de fraude détectée, la commission peut être annulée tout en précisant le
                  motif de refus.
                  <br />
                  <br />
                  En cas de suspicion sur les pratiques d'un affilié, il est aussi possible de le
                  placer en quarantaine en attendant d'éclaircir la situation.
                </p>
              </div>

              <div className="mt-10 -mx-4 relative lg:mt-0 lg:col-start-1">
                <img className="relative mx-auto" width={400} src={illuDefense} alt="" />
              </div>
            </div>
          </div>
          <div className="relative mt-12 sm:mt-16 lg:mt-24">
            <div className="lg:grid lg:grid-flow-row-dense lg:grid-cols-2 lg:gap-8 lg:items-center">
              <div className="lg:col-start-1">
                <h3 className="text-2xl font-extrabold text-green-brand font-title tracking-tight sm:text-3xl">
                  Gagnez en sérénité
                </h3>
                <p className="mt-3 text-lg text-gray-500">
                  N'ayez plus peur du premier jour du mois et validez les commissions en moins de 5
                  minutes. Plus besoin de croiser des données dans tous les sens, tout est
                  automatisé ! Un délai de validation plus court c'est aussi des affiliés heureux
                  qui pourront être rémunérés plus tôt par la plateforme.
                </p>
              </div>

              <div className="mt-10 -mx-4 relative lg:mt-0 lg:col-start-2">
                <img className="relative mx-auto" width={400} src={illuPeaceful} alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="bg-gray-50">
        <div className="max-w-4xl mx-auto py-16 px-4 sm:px-6 sm:py-24 lg:max-w-7xl lg:px-8 lg:flex lg:items-center lg:justify-between">
          <h2 className="text-2xl font-title font-extrabold tracking-tight text-gray-900 sm:text-4xl">
            <span className="block">Vous souhaitez en savoir plus ?</span>
            <span className="block text-green-brand">Faites une demande de démo</span>
          </h2>
          <div className="mt-6 space-y-4 sm:space-y-0 sm:flex sm:space-x-5">
            <Link
              to="/contact"
              className="flex items-center justify-center bg-green-brand px-4 py-3 border border-transparent text-base font-medium rounded-md shadow-sm text-white hover:bg-green-brandHover"
            >
              Demande de démo
            </Link>
          </div>
        </div>
      </div>
      <Testimonials />
    </PageLayout>
  );
};

export default Page;
